// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-gallery-js": () => import("./../../../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-goal-js": () => import("./../../../src/pages/goal.js" /* webpackChunkName: "component---src-pages-goal-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-intro-js": () => import("./../../../src/pages/intro.js" /* webpackChunkName: "component---src-pages-intro-js" */),
  "component---src-pages-skill-js": () => import("./../../../src/pages/skill.js" /* webpackChunkName: "component---src-pages-skill-js" */),
  "component---src-pages-timeline-js": () => import("./../../../src/pages/timeline.js" /* webpackChunkName: "component---src-pages-timeline-js" */)
}

